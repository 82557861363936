import { useState } from "react";
import "./App.scss";
import Modal from "./components/Modal/Modal";
import SummonModal from "./components/SummonModal/SummonModal";
import SummonCardSet from "./components/SummonCardSet/SummonCardSet";
import { getCurrentTier } from "./utilties/utilities";
import Headline from "./components/Headline/Headline";
import Score from "./components/Score/Score";
import WeaponCardSet from "./components/WeaponCardSet/WeaponCardSet";
import History from "./components/History/History";
import HistoryModal from "./components/HistoryModal/HistoryModal";

function App() {
  // const monstersArr = [
  //   {
  //     attack: 2,
  //     speed: 6,
  //     health: 5,
  //     special: "Meteor Strike: Deal massive damage to a random enemy",
  //     image: "IMAGE",
  //     name: "Helpful Dove",
  //     tier: "I",
  //   },
  //   {
  //     attack: 5,
  //     speed: 11,
  //     health: 5,
  //     special:
  //       "Mind Control: Take control of an opponent’s monster for one turn",
  //     image: "IMAGE",
  //     name: "Gleeful Bear",
  //     tier: "III",
  //   },
  //   {
  //     attack: 6,
  //     speed: 15,
  //     health: 4,
  //     special: "Charge: Increase attack power for the next turn",
  //     image: "IMAGE",
  //     name: "Joyful undefined",
  //     tier: "IV",
  //   },
  //   {
  //     attack: 6,
  //     speed: 7,
  //     health: 9,
  //     special: null,
  //     image: "IMAGE",
  //     name: "Awe-inspiring Fox",
  //     tier: "III",
  //   },
  //   {
  //     attack: 3,
  //     speed: 9,
  //     health: 4,
  //     special: null,
  //     image: "IMAGE",
  //     name: "Grateful Crane",
  //     tier: "II",
  //   },
  // ];
  //STATE PLAN
  const [points, setPoints] = useState(0);
  const [selectedDifficulty, setSelectedDifficulty] = useState(0);
  const [monsters, setMonsters] = useState([]);
  const [openMonsterModal, setOpenMonsterModal] = useState(false);
  const [openHistoryModal, setOpenHistoryModal] = useState(false);

  function onAddHandler() {
    const newPoints = points + 1;
    setPoints(newPoints);
  }
  function onRemoveHandler() {
    if (points === 0) return; // guard clause
    const newPoints = points - 1;
    setPoints(newPoints);
  }

  function onSetPoints(e) {
    const inputValue = e.target.value;
    if (inputValue === "") {
      setPoints(""); // Allow the input to be empty
    } else {
      setPoints(Number(inputValue));
    }
  }

  function onResetHandler() {
    setPoints(0);
  }

  function handleMonsterModalToggle(event) {
    setOpenMonsterModal(!openMonsterModal);
  }
  function handleHistoryModalToggle(event) {
    setOpenHistoryModal(!openHistoryModal);
  }

  return (
    <main className="app">
      <Headline points={points} />
      <Score
        onRemoveHandler={onRemoveHandler}
        points={points}
        onSetPoints={onSetPoints}
        onResetHandler={onResetHandler}
        onAddHandler={onAddHandler}
      />
      <SummonCardSet
        points={points}
        handleMonsterModalToggle={handleMonsterModalToggle}
        setSelectedDifficulty={setSelectedDifficulty}
      />
      <WeaponCardSet points={points} />
      <History
        monsters={monsters}
        handleHistoryModalToggle={handleHistoryModalToggle}
      />
      {openMonsterModal ? (
        <Modal
          modalTitle="Monster Summoner"
          dismissHandler={handleMonsterModalToggle}
        >
          <SummonModal
            points={points}
            dismissHandler={handleMonsterModalToggle}
            getCurrentTier={getCurrentTier}
            selectedDifficulty={selectedDifficulty}
            setSelectedDifficulty={setSelectedDifficulty}
            setMonsters={setMonsters}
          />
        </Modal>
      ) : (
        ""
      )}
      {openHistoryModal ? (
        <Modal
          modalTitle="Summon history"
          dismissHandler={handleHistoryModalToggle}
        >
          <HistoryModal monsters={monsters} />
        </Modal>
      ) : (
        ""
      )}
    </main>
  );
}

export default App;
