import "./headline.scss";
import { convertToRoman, getCurrentTier } from "../../utilties/utilities";
import tiers from "../../data/tiers";

function Headline({ points }) {
  function calcProgress() {
    let target;
    let previousTier;
    if (getCurrentTier(points).currentTier === 1) {
      target = tiers["tierOne"];
      previousTier = 0;
    }
    if (getCurrentTier(points).currentTier === 2) {
      target = tiers["tierTwo"] - tiers["tierOne"];
      previousTier = tiers["tierOne"];
    }
    if (getCurrentTier(points).currentTier === 3) {
      target = tiers["tierThree"] - tiers["tierTwo"];
      previousTier = tiers["tierTwo"];
    }
    if (getCurrentTier(points).currentTier === 4) {
      target = tiers["tierFour"] - tiers["tierThree"];
      previousTier = tiers["tierThree"];
    }
    if (getCurrentTier(points).currentTier === 5) {
      target = tiers["tierFive"] - tiers["tierFour"];
      previousTier = tiers["tierFour"];
    }

    return `${((points - previousTier) / target) * 100}%`;
  }

  return (
    <section className="headline">
      <div className="headline__tier">
        <h2>Tier tracker</h2>
        <p className="headline__count">
          {convertToRoman(getCurrentTier(points).currentTier)}
        </p>
      </div>
      <div>
        <p className="headline__bar-text">
          {getCurrentTier(points).tillNextTier} souls needed for tier{" "}
          {convertToRoman(getCurrentTier(points).currentTier + 1)}
        </p>
        <div className="headline__bar">
          <span
            className="headline__bar-fill"
            style={{ width: calcProgress() }}
          ></span>
        </div>
      </div>
    </section>
  );
}

export default Headline;
