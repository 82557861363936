import "./modal.scss";
import Button from "../Button/Button";

function Modal({ children, modalTitle, dismissHandler }) {
  return (
    <div className="modal__overlay">
      <main className="modal__container">
        <div className="modal__title-group">
          <h3 className="modal__title">{modalTitle}</h3>
          <Button clickHandler={dismissHandler} classes="modal__button">
            X
          </Button>
        </div>
        <div className="modal__content">{children}</div>
      </main>
    </div>
  );
}

export default Modal;
