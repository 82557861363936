import "./history-modal.scss";

function HistoryModal({ monsters }) {
  const historyArr = [];
  for (let i = 0; i < monsters.length; i++) {
    const currentMonster = monsters[i];
    historyArr.push(<div key={i}>{currentMonster.name}</div>);
  }

  return (
    <div>{monsters.length !== 0 ? historyArr : "No monsters summoned"}</div>
  );
}

export default HistoryModal;
