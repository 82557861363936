import "./summon-card-set.scss";
import Card from "../Card/Card";
import { convertToRoman, getCurrentTier } from "../../utilties/utilities";

function SummonCardSet({
  points,
  handleMonsterModalToggle,
  setSelectedDifficulty,
}) {
  const cardNum = 5;
  const summonCardsArr = [];
  for (let i = 0; i < cardNum; i++) {
    summonCardsArr.push(
      <Card
        icon="skull"
        tier={i + 1}
        key={i + 1}
        currentTier={getCurrentTier(points).currentTier}
        clickHandler={handleMonsterModalToggle}
        cardType="selection"
        setSelectedDifficulty={setSelectedDifficulty}
      >
        {convertToRoman(i + 1)}
      </Card>
    );
  }

  return (
    <section className="summon-set">
      <h2 className="summon-set__title">Monster Summoner</h2>
      <div className="summon-set__cards">{summonCardsArr}</div>
    </section>
  );
}

export default SummonCardSet;
