const adjectives = [
  "Adventurous",
  "Aggressive",
  "Agreeable",
  "Alert",
  "Ambitious",
  "Amused",
  "Angry",
  "Annoyed",
  "Anxious",
  "Apathetic",
  "Arrogant",
  "Ashamed",
  "Astonished",
  "Attentive",
  "Attractive",
  "Auspicious",
  "Awe-inspiring",
  "Awkward",
  "Beautiful",
  "Belligerent",
  "Bitter",
  "Bizarre",
  "Blissful",
  "Boastful",
  "Bold",
  "Brave",
  "Bright",
  "Brilliant",
  "Calm",
  "Careful",
  "Caring",
  "Cautious",
  "Charming",
  "Cheerful",
  "Chilly",
  "Clever",
  "Clumsy",
  "Confident",
  "Confused",
  "Conscientious",
  "Considerate",
  "Courageous",
  "Creative",
  "Cuddly",
  "Curious",
  "Daring",
  "Dashing",
  "Delightful",
  "Determined",
  "Diligent",
  "Doubtful",
  "Dramatic",
  "Dreamy",
  "Drowsy",
  "Eager",
  "Earnest",
  "Ecstatic",
  "Efficient",
  "Elegant",
  "Eloquent",
  "Energetic",
  "Enthusiastic",
  "Envious",
  "Excited",
  "Exuberant",
  "Fair",
  "Faithful",
  "Fearless",
  "Fierce",
  "Friendly",
  "Funny",
  "Generous",
  "Gentle",
  "Glamorous",
  "Gleeful",
  "Gloomy",
  "Grateful",
  "Greedy",
  "Grumpy",
  "Happy",
  "Harmonious",
  "Helpful",
  "Heroic",
  "Hilarious",
  "Hopeful",
  "Humorous",
  "Imaginative",
  "Impatient",
  "Impressive",
  "Indifferent",
  "Industrious",
  "Inquisitive",
  "Inspiring",
  "Intelligent",
  "Intuitive",
  "Inventive",
  "Jealous",
  "Jovial",
  "Joyful",
  "Jubilant",
  "Keen",
  "Kind",
  "Knowledgeable",
  "Lively",
  "Loyal",
  "Luminous",
  "Lush",
  "Magnificent",
  "Majestic",
  "Marvelous",
  "Mature",
  "Melancholic",
  "Mellow",
  "Merry",
  "Mischievous",
  "Modest",
  "Mysterious",
  "Naive",
  "Narrow-minded",
  "Nasty",
  "Naughty",
  "Neat",
  "Nervous",
  "Noble",
  "Nostalgic",
  "Observant",
  "Obstinate",
  "Optimistic",
  "Outstanding",
  "Outspoken",
  "Passionate",
  "Patient",
  "Peaceful",
  "Pensive",
  "Perceptive",
  "Persistent",
  "Persuasive",
  "Pessimistic",
  "Philosophical",
  "Playful",
  "Pleasant",
  "Plucky",
  "Polite",
  "Pompous",
  "Practical",
  "Proud",
  "Prudent",
  "Quaint",
  "Quick",
  "Quiet",
  "Radiant",
  "Rational",
  "Rebellious",
  "Receptive",
  "Reflective",
  "Reliable",
  "Resilient",
  "Resourceful",
  "Respectful",
  "Responsible",
  "Restless",
  "Romantic",
  "Ruthless",
  "Sassy",
  "Savvy",
  "Sensitive",
  "Sentimental",
  "Serene",
  "Sharp",
  "Shrewd",
  "Shy",
  "Sincere",
  "Skeptical",
  "Sly",
  "Sociable",
  "Sophisticated",
  "Spirited",
  "Spontaneous",
  "Steadfast",
  "Stealthy",
  "Stoic",
  "Strategic",
  "Stubborn",
  "Subtle",
  "Supportive",
  "Surly",
  "Sympathetic",
  "Talented",
  "Tame",
  "Tender",
  "Thoughtful",
  "Tolerant",
  "Tough",
  "Trustworthy",
  "Unassuming",
  "Understanding",
  "Unique",
  "Vain",
  "Vibrant",
  "Vigilant",
  "Vigorous",
  "Vivacious",
  "Warm",
  "Wary",
  "Witty",
  "Wise",
  "Wistful",
  "Witty",
  "Youthful",
  "Zealous",
];
const nouns = [
  "Aardvark",
  "Albatross",
  "Alligator",
  "Alpaca",
  "Ant",
  "Anteater",
  "Antelope",
  "Armadillo",
  "Baboon",
  "Badger",
  "Barracuda",
  "Bat",
  "Bear",
  "Beaver",
  "Bee",
  "Bison",
  "Boar",
  "Buffalo",
  "Butterfly",
  "Camel",
  "Capybara",
  "Caribou",
  "Cassowary",
  "Cat",
  "Caterpillar",
  "Cheetah",
  "Chicken",
  "Chimpanzee",
  "Chinchilla",
  "Clam",
  "Cobra",
  "Cockroach",
  "Cod",
  "Condor",
  "Cougar",
  "Coyote",
  "Crab",
  "Crane",
  "Crocodile",
  "Crow",
  "Deer",
  "Dinosaur",
  "Dog",
  "Dolphin",
  "Donkey",
  "Dove",
  "Dragonfly",
  "Duck",
  "Eagle",
  "Echidna",
  "Eel",
  "Elephant",
  "Elk",
  "Emu",
  "Falcon",
  "Ferret",
  "Finch",
  "Flamingo",
  "Fox",
  "Frog",
  "Gazelle",
  "Gecko",
  "Giraffe",
  "Goat",
  "Goldfish",
  "Goose",
  "Gorilla",
  "Grasshopper",
  "Gull",
  "Hamster",
  "Hare",
  "Hawk",
  "Hedgehog",
  "Heron",
  "Hippopotamus",
  "Horse",
  "Human",
  "Hyena",
  "Iguana",
  "Jackal",
  "Jaguar",
  "Jellyfish",
  "Kangaroo",
  "Koala",
  "Kudu",
  "Ladybug",
  "Lark",
  "Leopard",
  "Lion",
  "Lizard",
  "Llama",
  "Lobster",
  "Lynx",
  "Macaw",
  "Magpie",
  "Mallard",
  "Mantis",
  "Marmot",
  "Meerkat",
  "Mink",
];
export { adjectives, nouns };
