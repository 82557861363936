const statTable = {
  attack: {
    1: [1, 1, 1, 2, 2, 2],
    2: [2, 2, 3, 3, 4, 4],
    3: [3, 3, 4, 4, 5, 6],
    4: [4, 5, 6, 7, 8, 9],
    5: [6, 8, 10, 12, 14, 16],
  },
  speedHealth: {
    1: [
      [3, 8],
      [4, 7],
      [5, 6],
      [6, 5],
      [7, 4],
      [8, 3],
    ],
    2: [
      [4, 9],
      [5, 8],
      [6, 7],
      [7, 6],
      [8, 5],
      [9, 4],
    ],
    3: [
      [5, 11],
      [6, 10],
      [7, 9],
      [9, 7],
      [10, 6],
      [11, 5],
    ],
    4: [
      [5, 14],
      [7, 12],
      [9, 10],
      [11, 8],
      [13, 6],
      [15, 4],
    ],
    5: [
      [6, 16],
      [8, 14],
      [10, 12],
      [12, 10],
      [14, 8],
      [16, 6],
    ],
  },
};

export default statTable;
