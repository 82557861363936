import "./history.scss";
import Button from "../Button/Button";

function History({ monsters, handleHistoryModalToggle }) {
  return (
    <section className="history">
      <Button
        type="text"
        classes="history__button"
        clickHandler={() => {
          handleHistoryModalToggle();
          console.log(monsters);
        }}
      >
        Monster Summons History
      </Button>
      <Button
        type="text"
        classes="history__button"
        clickHandler={() => {
          console.log("weapon history clicked");
        }}
      >
        Weapon Crafts History
      </Button>
    </section>
  );
}

export default History;
