import tiers from "../data/tiers";

function convertToRoman(num) {
  const romanNumerals = [
    { value: 1000, numeral: "M" },
    { value: 900, numeral: "CM" },
    { value: 500, numeral: "D" },
    { value: 400, numeral: "CD" },
    { value: 100, numeral: "C" },
    { value: 90, numeral: "XC" },
    { value: 50, numeral: "L" },
    { value: 40, numeral: "XL" },
    { value: 10, numeral: "X" },
    { value: 9, numeral: "IX" },
    { value: 5, numeral: "V" },
    { value: 4, numeral: "IV" },
    { value: 1, numeral: "I" },
  ];

  let roman = "";

  for (const { value, numeral } of romanNumerals) {
    while (num >= value) {
      roman += numeral;
      num -= value;
    }
  }

  return roman;
}

function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getCurrentTier(points) {
  switch (true) {
    case points >= 0 && points <= tiers["tierOne"] - 1:
      return {
        currentTier: 1,
        tillNextTier: tiers["tierOne"] - points,
      };
    case points >= tiers["tierOne"] && points <= tiers["tierTwo"] - 1:
      return {
        currentTier: 2,
        tillNextTier: tiers["tierTwo"] - points,
      };
    case points >= tiers["tierTwo"] && points <= tiers["tierThree"] - 1:
      return {
        currentTier: 3,
        tillNextTier: tiers["tierThree"] - points,
      };
    case points >= tiers["tierThree"] && points <= tiers["tierFour"] - 1:
      return {
        currentTier: 4,
        tillNextTier: tiers["tierFour"] - points,
      };
    case points >= tiers["tierFour"] && points <= tiers["tierFive"] - 1:
      return {
        currentTier: 5,
        tillNextTier: tiers["tierFive"] - points,
      };
    default:
      return {
        currentTier: 1,
        tillNextTier: 0,
      };
  }
}

export { convertToRoman, getRandomNumber, getCurrentTier };
