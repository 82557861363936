const specials = [
  "Double Attack: Attack twice in one turn",
  "Poison Strike: Inflict poison damage over time",
  "Life Steal: Regain health equal to half the damage dealt",
  "Shield Bash: Deal damage and reduce the opponent’s next attack by half",
  "Stun: Skip the opponent’s next turn",
  "Burn: Inflict fire damage over multiple turns",
  "Freeze: Prevent the opponent from attacking for one turn",
  "Silence: Disable the opponent’s abilities for one turn",
  "Regeneration: Heal a small amount of health each turn",
  "Thorns: Reflect a portion of damage back to the attacker",
  "Berserk: Increase attack power when health is below 50%",
  "Vampiric Aura: All attacks by this monster heal its owner",
  "Invisibility: Cannot be targeted for one turn",
  "Counterattack: Automatically attack back when hit",
  "Curse: Reduce the opponent’s attack power for three turns",
  "Critical Strike: 50% chance to deal double damage",
  "Paralyze: Reduce the opponent’s speed for two turns",
  "Drain Mana: Reduce the opponent’s mana points",
  "Sacrifice: Destroy this monster to summon a more powerful one",
  "Earthquake: Deal damage to all enemies",
  "Flight: Immune to ground-based attacks",
  "Pierce: Ignore shields and defenses",
  "Swap: Exchange positions with another monster on the field",
  "Taunt: Force the opponent to target this monster",
  "Rebirth: Revive this monster with half health after it’s destroyed",
  "Amplify: Increase the power of spells by 20%",
  "Elemental Shift: Change the monster’s element to counter the opponent",
  "Camouflage: Reduce the chances of being targeted by 50%",
  "Rage: Gain increased attack power each time this monster is damaged",
  "Chain Lightning: Attack multiple enemies in succession",
  "Bloodlust: Increase attack power for every enemy defeated",
  "Teleport: Swap positions with any other monster",
  "Mind Control: Take control of an opponent’s monster for one turn",
  "Toxic Cloud: Inflict poison damage to all enemies",
  "Mana Burst: Sacrifice all mana to deal massive damage",
  "Protective Aura: Reduce damage taken by all allied monsters",
  "Reflect: Bounce back the next spell cast at this monster",
  "Crush Armor: Reduce the opponent’s defense for three turns",
  "Summon Minions: Call two weaker monsters to the field",
  "Detonate: Destroy this monster to deal damage to all enemies",
  "Aura of Fear: Reduce the attack power of all enemies",
  "Healing Touch: Heal an allied monster",
  "Blizzard: Reduce all enemies’ speed for two turns",
  "Guardian: Sacrifice this monster to protect another from damage",
  "Time Warp: Gain an extra turn",
  "Life Link: Share damage between this monster and an ally",
  "Echo: Repeat the last ability used",
  "Mana Shield: Convert incoming damage to mana loss",
  "Siphon Life: Drain health from the opponent and heal this monster",
  "Confuse: Randomize the target of the opponent’s attacks",
  "Mimic: Copy the opponent’s last ability",
  "Shield Wall: Increase the defense of all allied monsters",
  "Empower: Boost the attack of an allied monster for one turn",
  "Summon Golem: Call a powerful defensive monster to the field",
  "Weaken: Halve the attack power of the opponent for one turn",
  "Snare: Prevent the opponent from switching monsters",
  "Revenge: Deal double damage if this monster was attacked last turn",
  "Haste: Allow an allied monster to act twice in the next turn",
  "Devour: Destroy a weaker enemy monster and regain health",
  "Disarm: Prevent the opponent from using special abilities",
  "Phoenix Fire: Revive this monster at full health upon death",
  "Charge: Increase attack power for the next turn",
  "Nullify: Cancel the opponent’s next ability",
  "Acid Spit: Reduce the opponent’s defense permanently",
  "Plague: Spread a weakening effect to all enemies",
  "Soulbind: Damage an opponent whenever this monster takes damage",
  "Fortify: Increase this monster’s defense for three turns",
  "Volcanic Eruption: Deal heavy fire damage to all enemies",
  "Energy Drain: Transfer the opponent’s mana to this monster",
  "Evasion: Dodge the next attack",
  "Aura of Courage: Boost all allied monsters' attack power",
  "Meteor Strike: Deal massive damage to a random enemy",
  "Charm: Reduce the opponent’s attack power for one turn",
  "Mind Blast: Reduce the opponent’s mana and health",
  "Dark Pact: Sacrifice health to gain mana",
  "Guardian Spirit: Reduce all incoming damage by 50%",
  "Hex: Inflict random negative status effects on the opponent",
  "Lightning Rod: Absorb all lightning-based attacks",
  "Fortress: Prevent all damage for one turn",
  "Shadow Step: Increase evasion and dodge the next attack",
  "Spirit Link: Heal all allied monsters when this monster is healed",
  "Unholy Frenzy: Gain attack power but lose health each turn",
  "Spectral Strike: Ignore defenses and deal direct damage",
  "Nightmare: Inflict sleep and poison on the opponent",
  "Banish: Remove the opponent’s monster from the battlefield for one turn",
  "Holy Light: Heal all allied monsters and remove negative effects",
  "Blood Pact: Increase attack power at the cost of health",
  "Thunder Clap: Stun all enemies for one turn",
  "Forcefield: Block the next three attacks",
  "Leech: Absorb a portion of the opponent’s health each turn",
  "Mind Shatter: Reduce the opponent’s mental abilities",
  "Phoenix Rebirth: Return to life with full health after being defeated",
  "Dark Ritual: Sacrifice an allied monster to fully heal this one",
  "Ethereal Form: Immune to physical attacks for two turns",
  "Frostbite: Reduce the opponent’s speed and attack power",
  "Mind Flay: Reduce the opponent’s mana and deal damage",
  "Blood Fury: Increase attack power when health is low",
  "Ancient Power: Gain a powerful boost in attack and defense",
  "Winds of Change: Shuffle all monsters on the field",
  "Soul Harvest: Gain health for each defeated enemy",
  "Meteor Swarm: Deal random damage to all enemies",
];
export { specials };
