import "./card.scss";
import icons from "../../assets/icons";
import parse from "html-react-parser";

function Card({
  children,
  clickHandler,
  currentTier,
  tier,
  icon,
  setSelectedDifficulty,
  selectedDifficulty,
  cardType = "default",
}) {
  return (
    <div
      className={`card ${tier === selectedDifficulty ? "selected" : ""}`}
      onClick={(e) => {
        if (cardType === "selection") {
          setSelectedDifficulty(tier);
        }
        clickHandler(e.target);
      }}
    >
      <div className="card__icon">{icon ? parse(icons[icon]) : ""}</div>
      <p className="card__text">{children}</p>
      {currentTier === tier ? <div className="suggested"></div> : ""}
    </div>
  );
}

export default Card;
