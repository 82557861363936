import "./score.scss";
import Button from "../Button/Button";

function Score({
  onRemoveHandler,
  points,
  onSetPoints,
  onResetHandler,
  onAddHandler,
}) {
  return (
    <section className="score">
      <Button type="icon" clickHandler={onRemoveHandler}>
        -
      </Button>
      <div className="score__control">
        <h2 className="score__title">Soul tracker</h2>
        <input
          className="score__input"
          type="text"
          value={points}
          onChange={onSetPoints}
          placeholder="..."
        />
        <Button type="text" clickHandler={onResetHandler}>
          Reset
        </Button>
      </div>
      <Button type="icon" clickHandler={onAddHandler}>
        +
      </Button>
    </section>
  );
}

export default Score;
