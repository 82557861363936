import "./button.scss";

function Button({ children, clickHandler, type, classes }) {
  return (
    <button
      className={`button button__${type} ${classes}`}
      onClick={() => {
        clickHandler();
      }}
    >
      {children}
    </button>
  );
}

export default Button;
