import "./summon-modal.scss";
import { useState, useEffect } from "react";
import parse from "html-react-parser";
import axios from "axios";
import Button from "../Button/Button";
import Card from "../Card/Card";
import {
  convertToRoman,
  getRandomNumber,
  getCurrentTier,
} from "../../utilties/utilities";
import rogue from "../../assets/images/rogue.jpg";
import icons from "../../assets/icons";
import { nouns, adjectives } from "../../data/monsterNames";
import { specials } from "../../data/monsterSpecials";
import statTable from "../../data/statTable";

function SummonModal({
  points,
  dismissHandler,
  setSelectedDifficulty,
  selectedDifficulty,
  setMonsters,
}) {
  //state
  const [step, setStep] = useState("summon");
  const [monster, setMonster] = useState({});
  const [specialEffect, setSpecialEffect] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  //variables
  const currentTier = getCurrentTier(points).currentTier;
  // console.log(process.env.REACT_APP_OA_KEY);

  //lifecycle functions
  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.post(
          "https://api.openai.com/v1/images/generations",
          {
            prompt: "a thunder otter",
            n: 1,
            size: "256x256",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${process.env.REACT_APP_OA_KEY}`,
            },
          }
        );
        setImageUrl(response.data.data[0].url);
      } catch (error) {
        console.error("Error fetching the image:", error);
      }
    };
    // fetchImage();
  }, []);

  //regular functions
  function handleSummonClick() {
    const special = specialEffect
      ? specials[getRandomNumber(1, specials.length)]
      : null;

    if (step === "summon") {
      setStep("monster");

      const monsterAttack =
        statTable["attack"][selectedDifficulty][getRandomNumber(0, 5)];

      const monsterStats =
        statTable["speedHealth"][selectedDifficulty][getRandomNumber(0, 5)];

      const newMonster = {
        attack: monsterAttack,
        speed: monsterStats[0],
        health: monsterStats[1],
        special,
        image: "IMAGE",
        name: `${adjectives[getRandomNumber(1, specials.length)]} ${
          nouns[getRandomNumber(1, specials.length)]
        }`,
        tier: convertToRoman(selectedDifficulty),
      };
      setMonster(newMonster);
      setMonsters((prevMonsterArr) => [...prevMonsterArr, newMonster]);
    } else {
      setStep("summon");
    }
  }

  function handleFinishClick() {
    setStep("summon");
    dismissHandler();
  }

  function handleSpecialChange() {
    setSpecialEffect(!specialEffect);
  }

  const cardNum = 5;
  const cardsArr = [];
  for (let i = 0; i < cardNum; i++) {
    cardsArr.push(
      <Card
        isSelected={false}
        icon="skull"
        key={i}
        tier={i + 1}
        currentTier={currentTier}
        clickHandler={() => {}}
        cardType="selection"
        selectedDifficulty={selectedDifficulty}
        setSelectedDifficulty={setSelectedDifficulty}
      >
        {convertToRoman(i + 1)}
      </Card>
    );
  }

  return (
    <div className="summon-modal">
      {step === "summon" ? (
        <section className="summon-modal__summon-group">
          <div className="summon-modal__summon-cards">{cardsArr}</div>
          <div>
            <label>
              <input
                type="checkbox"
                checked={specialEffect}
                onChange={handleSpecialChange}
              />
            </label>
            <p>
              Include monster effects?<span>Reccomended for tier IV+</span>{" "}
            </p>
          </div>
          <Button
            clickHandler={handleSummonClick}
            classes="summon-modal__button"
          >
            Summon
          </Button>
        </section>
      ) : (
        <section className="summon-modal__monster-group">
          <div className="summon-modal__monster-title-group">
            <h5>{monster.tier}</h5>
            <p>{monster.name}</p>
          </div>

          <div>
            <div className="summon-modal__image-container">
              <img src={rogue} alt="rogue" />
            </div>
            {/* <div>{monster.image}</div> */}
            {/* {imageUrl ? (
              <img src={imageUrl} alt="Generated by OpenAI" />
            ) : (
              <p>Loading ...</p>
            )} */}
          </div>
          <div className="summon-modal__monster-info">
            <div className="summon-modal__stats-group">
              <div className="summon-modal__monster-stat">
                {parse(icons["sword"])}
                <p>{monster.attack}</p>
              </div>
              <div className="summon-modal__monster-stat">
                {parse(icons["lightning"])}
                <p>{monster.speed}</p>
              </div>
              <div className="summon-modal__monster-stat">
                {parse(icons["heart"])}
                <p>{monster.health}</p>
              </div>
            </div>
            {monster.special ? (
              <p className="summon-modal__monster-special">{monster.special}</p>
            ) : (
              ""
            )}
          </div>

          <Button
            clickHandler={handleFinishClick}
            classes="summon-modal__button"
          >
            Finish
          </Button>
        </section>
      )}
    </div>
  );
}

export default SummonModal;
