const icons = {
  sword:
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M4.035.854.323.324l.53 3.712 8.004 8.003L7 12.411l.295 1.47 4.606-.92 2.716 2.716 1.06-1.06-2.715-2.717.92-4.607L12.412 7l-.372 1.859L4.035.854Zm5.968 10.21L2.268 3.328 2.09 2.091l1.237.177 7.736 7.735v.793l-.268.268h-.793Z"/></svg>',
  skull:
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M5.828 1.75c-.331 0-.65.132-.884.366L3.002 4.06a1.25 1.25 0 0 0-.354.707l-.722 5.051c-.07.49.156.975.575 1.237l1.956 1.223A2.75 2.75 0 0 1 5.75 14.61V16h-1.5v-1.392a1.25 1.25 0 0 0-.588-1.06l-1.956-1.222a2.75 2.75 0 0 1-1.264-2.72l.721-5.052a2.75 2.75 0 0 1 .778-1.556l1.943-1.943A2.75 2.75 0 0 1 5.828.25h4.343c.73 0 1.43.29 1.945.805l1.943 1.943c.42.421.694.967.778 1.556l.721 5.051a2.75 2.75 0 0 1-1.265 2.721l-1.956 1.223a1.25 1.25 0 0 0-.587 1.06V16h-1.5v-1.392a2.75 2.75 0 0 1 1.292-2.332l1.956-1.222a1.25 1.25 0 0 0 .575-1.237l-.721-5.051a1.25 1.25 0 0 0-.354-.707l-1.943-1.943a1.25 1.25 0 0 0-.883-.366H5.827Z"/><path d="M7.25 16v-3h1.5v3h-1.5ZM5.5 9.5a1.5 1.5 0 0 0 1.418-1.01C7.008 8.23 6.776 8 6.5 8h-2c-.276 0-.508.23-.418.49A1.5 1.5 0 0 0 5.5 9.5Zm5 0a1.5 1.5 0 0 0 1.418-1.01c.09-.26-.142-.49-.418-.49h-2c-.276 0-.508.23-.418.49A1.5 1.5 0 0 0 10.5 9.5Zm-3.479 1.479.696-.696a.4.4 0 0 1 .566 0l.696.696c.287.287.013.77-.38.67l-.502-.125a.398.398 0 0 0-.194 0l-.502.126c-.394.098-.667-.384-.38-.671Z"/></svg>',
  heart:
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><g clip-path="url(#a)"><path d="M1.394 2.144A4.76 4.76 0 0 1 8 2.024a4.76 4.76 0 0 1 6.606 6.851L8 15.481 1.394 8.875a4.76 4.76 0 0 1 0-6.73Z"/></g><defs><clipPath id="a"><path d="M0 0h16v16H0z"/></clipPath></defs></svg>',
  lightning:
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M7.157 0 2.333 9.408l-.56 1.092H7a.25.25 0 0 1 .25.25V16h1.593l4.824-9.408.56-1.092H9a.25.25 0 0 1-.25-.25V0H7.157ZM7 9H4.227L7.25 3.106V5.25C7.25 6.216 8.033 7 9 7h2.772L8.75 12.894V10.75A1.75 1.75 0 0 0 7 9Z"/></svg>',
};

export default icons;
