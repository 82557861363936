import "./weapon-card-set.scss";
import { convertToRoman, getCurrentTier } from "../../utilties/utilities";
import Card from "../Card/Card";

function WeaponCardSet({ points }) {
  const cardNum = 5;
  const weaponCardsArr = [];
  for (let i = 0; i < cardNum; i++) {
    weaponCardsArr.push(
      <Card
        icon="sword"
        tier={i + 1}
        key={i + 1}
        currentTier={getCurrentTier(points).currentTier}
        clickHandler={() => {}}
      >
        {convertToRoman(i + 1)}
      </Card>
    );
  }
  return (
    <section className="weapon-set">
      <h2 className="weapon-set__title">Weapon Crafter</h2>
      <div className="weapon-set__cards">{weaponCardsArr}</div>
    </section>
  );
}

export default WeaponCardSet;
